import React from "react";

import {Link} from 'gatsby';
import Layout from "../components/layout";
import PurpleRow from "../components/purpleRow";

function getUrlVars() {
  var vars = {};
  if (typeof window !== "undefined") {
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
  }
  return vars;
}

const OrderPage = () => {
  const onSend = (e) => {
    var url = "https://devsystem.kidsup.pl/order";
    $(".orderokmessage").hide();
    $(".ordererrormessage").hide();
    $.ajax({
      type: "POST",
      url: url,
      data: $("#contact_form_order").serialize(),
      success: function (data) {
        if (data == "OK") {
          $(".orderokmessage").toggle();
        } else {
          $(".ordererrormessage").toggle();
        }
      },
    });
    e.preventDefault();
    return false;
  };

  return (
    <Layout title="Zamów">
      <div className="wrapper">
        <div className="section section-presentation">
          <div className="container">
            <div className="row">
              <div className="opinions-main-wrapper">
                <div className="col-sm-12 contact-main-text">
                  <h2>
                    <span className="purple">kids</span>
                    <span className="rose">up</span> – dla tych, którzy chcą
                    lepiej
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section " id="contact">
        <div className="container text-center middle-flex">
          <div
            className=" col-md-6 middle-flex"
            style={{ margin: "0 auto 50px", flexDirection: "column" }}
          >
            <form
              id="contact_form_order"
              action="#"
              method="POST"
              encType="multipart/form-data"
              style={{ paddingTop: 0 }}
              onSubmit={onSend}
            >
              {" "}
              <div className="row" style={{ display: "flex" }}>
                <input
                  id="name"
                  className="input input_field input-half"
                  required
                  name="name"
                  type="text"
                  size="30"
                  placeholder="Imię"
                  style={{ marginRight: "15px" }}
                />
                <br />
                <input
                  id="lastname"
                  className="input input_field input-half"
                  required
                  name="lastname"
                  type="text"
                  size="30"
                  placeholder="Nazwisko"
                />
                <br />
              </div>
              <div className="row">
                <input
                  id="phone"
                  className="input input_field"
                  required
                  name="phone"
                  type="text"
                  placeholder="Numer telefonu"
                  size="30"
                  onChange={() => {}}
                />
                <br />
              </div>
              <div className="row">
                <input
                  id="email"
                  className="input input_field"
                  required
                  name="email"
                  type="email"
                  placeholder="Służbowy adres email"
                  size="30"
                  onChange={() => {}}
                />
                <br />
              </div>
              <div className="row">
                <input
                  id="place"
                  className="input input_field"
                  required
                  name="place"
                  type="text"
                  placeholder="Nazwa placówki"
                  size="30"
                  onChange={() => {}}
                />
                <br />
              </div>
              <div className="row">
                <input
                  id="nip"
                  className="input input_field"
                  required
                  name="nip"
                  type="text"
                  placeholder="NIP placówki"
                  size="30"
                  onChange={() => {}}
                  pattern="^\d{10}$"
                />
                <br />
              </div>
              <div
                className="row bulgy-radios"
                role="radiogroup"
                aria-labelledby="bulgy-radios-label"
              >
                <label className="kidsup-radio">
                  <input
                    type="radio"
                    name="type"
                    value="mala"
                    defaultChecked={getUrlVars()["type"] === "small"}
                  />
                  <span className="radio-b"></span>
                  <span className="label-b">Przedszkole, do którego uczęszcza do 50 dzieci</span>
                </label>
                <label className="kidsup-radio">
                  <input
                    type="radio"
                    name="type"
                    value="srednia"
                    defaultChecked={getUrlVars()["type"] === "middle"}
                  />
                  <span className="radio-b"></span>
                  <span className="label-b">Przedszkole, do którego uczęszcza do 100 dzieci</span>
                </label>
                <label className="kidsup-radio">
                  <input
                    type="radio"
                    name="type"
                    value="duzo"
                    defaultChecked={getUrlVars()["type"] === "large"}
                  />
                  <span className="radio-b"></span>
                  <span className="label-b">Przedszkole powyżej 100 uczęszczających dzieci</span>
                </label>
                <label className="kidsup-radio">
                  <input
                    type="radio"
                    name="type"
                    value="wlasna"
                    defaultChecked={getUrlVars()["type"] === "other"}
                  />
                  <span className="radio-b"></span>
                  <span className="label-b">Przedszkole z oddziałami </span>
                </label>
              </div>
              <div className="row">
                <input
                  id="topic"
                  className="input input_field"
                  required
                  name="topic"
                  type="text"
                  placeholder="Temat rozmowy"
                  size="30"
                  onChange={() => {}}
                />
                <br />
              </div>
              <div className="row">
                <textarea
                  id="message"
                  required
                  className="input input_field"
                  style={{ height: "100px" }}
                  name="message"
                  placeholder="Wiadomość"
                  rows="7"
                  cols="30"
                ></textarea>
                <br />
              </div>
              <div
                className="orderokmessage alert alert-success"
                style={{ display: "none" }}
              >
                Wiadomość wysłana
              </div>
              <div
                className="ordererrormessage alert alert-danger"
                style={{ display: "none" }}
              >
                Wiadomość nie została wysłana - spróbuj ponownie później
              </div>
              <div className="middle-flex">
                <input
                  className="btn btn-fill btn-neutral send-btn contact-send-btn"
                  id="submit_button"
                  type="submit"
                  value="Wyślij"
                  style={{ margin: "0 auto" }}
                />
              </div>
            </form>
            <br />

            <div className={"terms"}>
              <input
                type="checkbox"
                id="terms"
                checked="checked"
                onChange={() => {}}
              />
              <label className="order_text" htmlFor="terms">
                Wyrażasz zgodę na kontakt i wysyłanie ofert handlowych przez
                kidsup S.A? Pełny regulamin oraz politykę przetwarzania danych
                osobowych znajdziesz <Link to="/prywatnosc"> W TYM MIEJSCU</Link>.
              </label>
            </div>
          </div>
        </div>
      </div>

      <PurpleRow>
        Aplikacja dostosowana <br />
        do potrzeb Twojego przedszkola
      </PurpleRow>
    </Layout>
  );
};

export default OrderPage;
